<template>
  <div class="p-wrap">
    <!-- 功能区 -->
    <div class="p-header">
      <div class="header-title">
        <div class="title-left">合同信息</div>
        <div class="title-right">
          <span @click="handleShowDialog('insert')"> <img src="@/assets/icon/goodsInfo/insert.png" />新增合同 </span>
          <!-- <span @click="importVisible = true"> <img src="@/assets/icon/goodsInfo/income.png" />批量导入 </span> -->
        </div>
      </div>
      <div class="header-select">
        <el-form class="form" ref="contractFormList" :model="filter">
          <el-row>
            <el-col :span="6">
              <el-form-item label="所属组织">
                <organization-select :model="filter"></organization-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="合同名称">
                <el-input v-model="filter.contractName" placeholder="请输入合同名称" @keyup.enter.native="handleQuery()" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="合同编号">
                <el-input v-model="filter.contractCode" placeholder="请输入合同编号" @keyup.enter.native="handleQuery()"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <span v-if="!selectExpended" @click="selectExpended = !selectExpended" style="color: #1890ff">展开<i class="el-icon-arrow-down"></i></span>
              <span v-else @click="selectExpended = !selectExpended" style="color: #1890ff">收起<i class="el-icon-arrow-up"></i></span>
              <el-button type="default" @click="handleReset">重置</el-button>
              <el-button type="primary" @click="handleQuery()">查询</el-button>
            </el-col>
          </el-row>
          <el-row style="margin-top: 10px" v-if="selectExpended">
            <el-col :span="6">
              <el-form-item label="合同类型">
                <el-select v-model="filter.contractType" placeholder="合同类型" clearable style="width: 220px">
                  <el-option v-for="type in contractTypeData" :key="type.value" :label="type.label" :value="type.value"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="相对方名称">
                <el-input v-model="filter.relativeName" placeholder="请输入相对方名称" @keyup.enter.native="handleQuery()"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <!-- 内容区 -->
    <div class="p-content">
      <div class="main-top">
        <div class="top-left">
          <span>合同列表</span>
        </div>
        <div class="top-right">
          <el-button type="primary" @click="handleBatchExport">批量导出</el-button>
          <!--
          <el-button type="danger" @click="handleBatchDelete">批量删除</el-button>
          -->
        </div>
      </div>

      <div class="p-table">
        <el-table ref="contractInfoTable" v-loading="tableLoading" :data="records" :header-cell-style="handleHeaderCellStyle" border stripe >
          <el-table-column fixed="left" type="selection" width="50"/>
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column show-overflow-tooltip label="合同编号" 
            prop="contractCode" min-width="120">
            <template slot-scope="scope">
              <el-link type="primary"
                       @click="handleClick(scope.row,'look')"
                       :underline="false">
                {{ scope.row.contractCode }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column
            prop="contractName"
            label="合同名称"
            align="center"
            min-width="120"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="customerNsrsbh"
            label="相对方纳税人识别号"
            align="center"
            min-width="120"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="relativeName"
            label="相对方名称"
            align="center"
            min-width="120"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="allAmount"
            label="合同金额（含税）"
            align="center"
            min-width="120"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="writeTime"
            label="合同签订日期"
            align="center"
            min-width="120"
            :formatter="handleDateFormat"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="contractStartTime"
            label="生效日期"
            align="center"
            min-width="120"
            :formatter="handleDateFormat"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="contractType"
            label="合同类型"
            align="center"
            min-width="120"
            :formatter="contractTypeFormat"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="status"
            label="合同状态"
            align="center"
            min-width="120"
            :formatter="statusFormat"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column fixed="right"  label="操作" prop="operate" width="110">
            <template slot-scope="scope">
              <div class="operate-button">
                <el-button size="medium" style="padding: 0" type="text" 
                  @click="handleShowDialog('update', scope.row)">
                  编辑
                </el-button>
                <el-button size="medium" style="padding: 0" type="text" 
                  @click="handleDelete(scope.row)">
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="records.length > 0" class="p-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleQuery" :current-page.sync="filter.page" :page-sizes="[15, 30, 50, 100]" :page-size="filter.size" layout="total, sizes, prev, pager, next" :total="total"></el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog append-to-body :title="editTitle" :visible.sync="editVisible" width="1150px" :show-close="true">
      <contract-edit v-if="editVisible" :lockObject="lockObject" :isEdit="isEdit" ref="contractForm" @handleCancelEditDialog="handleCancelEditDialog"></contract-edit>
    </el-dialog>
    <el-dialog append-to-body title="批量导入" :visible.sync="importVisible" width="500px" :show-close="false">
      <contract-import @handReloadContractList="handReloadContractList" @handleCancelImportDialog="importVisible = false"></contract-import>
    </el-dialog>
    <!-- 批量删除 -->
    <el-dialog append-to-body width="20%" :visible.sync="invoiceBatchDeleteVisible">
      <div class="dialog-delete">
        <span>是否删除选中合同信息?</span>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button type="default" @click="cancelDelete">取消</el-button>
        <el-button type="primary" @click="confirmDelete">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {tableStyle} from '@/util/mixins';
import {deleteContract, deleteContractByIds, exportContract, list} from '@/service/contract.js';
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import ContractImport from './contractImport';
import ContractEdit from './edit';

export default {
  name: 'contractList',
  components: { OrganizationSelect, ContractImport, ContractEdit },
  mixins: [tableStyle],
  data() {
    return {
      isIndeterminate: true,
      colOptions: [], //列选项
      checkAll: false, //全选
      selections: [], //选择行
      contractTypeData: [
        {label: '收入类', value: 1},
        {label: '支出类', value: 2},
      ],
      // 筛选条件
      filter: {
        orgId: Number(localStorage.getItem('orgId')),
        contractCode: '',
        contractName: '',
        nsrsbh: '',
        nsrmc: '',
        contractType: '',
        labelId: '',
        page: 1,
        size: 15
      },
      lockObject: {
        orgId: Number(localStorage.getItem('orgId')),
        code: undefined,
        name: ''
      },
      // 版本号信息
      versionInfo: {
        orgId: '',
        taxVersionNo: ''
      },
      tableLoading: false, // 表格加载loading
      versionVisible: false, // 版本号弹窗
      importVisible: false, // 导入弹窗
      editVisible: false, // 编辑弹窗
      editTitle: '', // 编辑弹窗标题
      isEdit: false, // 是否编辑
      records: [],
      total: 0, // 数据总数count
      serialNo: '', //合同编码
      invoiceBatchDeleteVisible: false,
      singleContractSign: false,
      singleContractId: '',
      selectExpended: false,
      params: {
        ids: ''
      }
    };
  },
  created() {
    this.handleQuery();
    this.settingOptions();
    // this.handleGetlineSet();
  },
  watch: {
    // colOptions(valArr) {
    //   const arr = this.colSelect.filter((i) => valArr.indexOf(i) < 0); // 未选中
    //   this.colData.filter((i) => {
    //     if (arr.indexOf(i.title) != -1) {
    //       i.isTrue = false;
    //       this.$nextTick(() => {
    //         this.$refs.customerInfoTable.doLayout();
    //       });
    //     } else {
    //       i.isTrue = true;
    //       this.$nextTick(() => {
    //         this.$refs.customerInfoTable.doLayout();
    //       });
    //     }
    //   });
    //   localStorage.setItem('customerInfo', JSON.stringify(this.colData));
    // }
  },
  methods: {
    handReloadContractList() {
      this.handleQuery();
    },
    // 合同类型
    contractTypeFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '合同类型':
          if (value === 1) {
            return '收入类';
          } else if (value === 2) {
            return '支出类';
          } else {
            return '';
          }
      }
    },
    // 合同状态
    statusFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '合同状态':
          if (value === 1) {
            return '正常';
          } else if (value === 2) {
            return '终止';
          } else {
            return '';
          }
      }
    },
    // 弹窗控制
    handleShowDialog(type, val) {
      this.editTitle = type == 'insert' ? '添加合同信息' : '编辑合同信息';
      this.isEdit = type == 'insert' ? false : true;
      this.lockObject = type == 'insert' ? {} : this.lockObject;
      this.editVisible = true;
      if (val) {
        this.lockObject = JSON.parse(JSON.stringify(val));
      } else {
        this.lockObject = {
          orgId: Number(localStorage.getItem('orgId')),
          code: undefined,
          name: undefined
        };
      }
    },
    // 重置表单
    handleReset() {
      this.filter.orgId = Number(localStorage.getItem('orgId'));
      this.filter.contractCode = '';
      this.filter.contractName = '';
      this.filter.contractType = '';
      this.filter.relativeName = '';
      this.filter.labelId = '';
    },
    // 查询
    async handleQuery(val) {
      this.filter.page = 1;
      if (val) {
        this.filter.page = val;
      }

      this.tableLoading = true;
      const { success, data } = await list(this.filter);
      this.tableLoading = false;
      if (success) {
        this.records = data.records;
        this.total = data.total;
      }
    },
    // 切换分页条数
    handleSizeChange(val) {
      this.filter.size = val;
      this.handleQuery();
    },
    handleClick(row, look){

    },
    // 删除合同
    async handleDelete(row) {
      this.invoiceBatchDeleteVisible = true;
      this.singleContractSign = true;
      this.singleContractId = row.id;
    },
    // 关闭合同dialog
    handleCancelEditDialog(status) {
      this.editVisible = false;
      if (status == 'success') {
        this.handleQuery();
      }
      this.$refs.contractForm.resetForm();
    },
    /* 表格数据选中 */
    handleSelection(value) {
      this.selections = value;
      this.params.ids = value.map((item) => item.id);
    },
    //重置列设置
    handleCheckReset() {
      this.colOptions = this.colSelect;
      this.checkAll = true;
      this.isIndeterminate = false;
    },
    //列设置全选
    handleCheckAllChange(val) {
      this.colOptions = val ? this.colSelect : [];
      this.isIndeterminate = false;
    },
    //列设置改变
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.colSelect.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.colSelect.length;
    },
    //导出excel
    async handleBatchExport() {
      if (this.selections.length == 0) {
        this.toast('请勾选需要导出的合同信息', 'warning');
        return;
      }
      let items = this.selections;
      this.exportLoading = true;
      let ids = items.map((item) => item.id);
      const rsp = await exportContract(ids);
      this.exportLoading = false;
      this.downloadFile(rsp);
    },
    /* 发票删除弹窗 */
    handleBatchDelete() {
      if (this.selections.length == 0) {
        this.toast('请勾选合同信息！', 'warning');
        return;
      }
      this.invoiceBatchDeleteVisible = true;
    },
    /* 批量删除选中合同 */
    async confirmDelete() {
      if (this.singleContractSign) {
        // 单条删除
        let ids = this.singleContractId;
        const { success, message } = await deleteContract(ids);
        if (success) {
          this.toast('删除成功', 'success', () => this.handleQuery());
          this.invoiceBatchDeleteVisible = false;
          this.cancelDelete();
        } else {
          this.toast(message, 'error');
          this.cancelDelete();
        }
      } else {
        // 批量删除
        let ids = this.selections.map((i) => i.id);
        const { success, message } = await deleteContractByIds(ids);
        if (success) {
          this.toast('删除成功！', 'success', () => {
            this.invoiceBatchDeleteVisible = false;
            this.handleQuery();
            this.cancelDelete();
          });
        } else {
          this.toast(message, 'error');
          this.cancelDelete();
        }
      }
      // 重置单条删除标记
      this.singleContractSign = false;
    },
    /* 取消选中 */
    cancelDelete() {
      this.selections = [];
      this.invoiceBatchDeleteVisible = false;
      this.$refs.contractInfoTable.clearSelection();
    },
    /* 时间格式化 */
    handleDateFormat(date) {
      if (date == undefined) {
        return '';
      }
      return this.$moment(date).format('YYYY-MM-DD');
    },
    settingOptions() {}
    //获取列设置
    // handleGetlineSet() {
    //   if (JSON.parse(localStorage.getItem('customerInfo')) != null) {
    //     this.colData = JSON.parse(localStorage.getItem('customerInfo'));
    //     if (this.colData.filter((i) => i.isTrue == true).length === this.colSelect.length) {
    //       this.checkAll = true;
    //       this.isIndeterminate = false;
    //     }
    //     this.colOptions = JSON.parse(localStorage.getItem('customerInfo'))
    //       .filter((i) => i.isTrue === true)
    //       .map((item) => item.title); //选中
    //   } else {
    //     this.colOptions = this.colData.map((item) => item.title); //默认所有
    //     this.checkAll = true;
    //     this.isIndeterminate = false;
    //   }
    // },
  }
};
</script>

<style scoped lang="scss">
.p-header {
  .header-title {
    display: flex;
    padding: 16px 24px;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;

    .title-right {
      display: inline-flex;

      span {
        color: #3d94ff;
        margin-right: 20px;
        font-weight: 500;
        font-size: 16px;

        img {
          width: 18px;
          height: 18px;
          vertical-align: sub;
          margin-right: 4px;
        }
      }

      span:hover {
        cursor: pointer;
      }
    }
  }
}

::v-deep .dialog-footer {
  padding-top: 25px;
  text-align: center;
}

::v-deep .p-content {
  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .top-left {
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 32px;
        font-size: 16px;
        color: #666666;
      }

      .rowSet {
        display: flex;
        align-items: center;
        cursor: pointer;

        .imgSet {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          margin-left: 5px;
        }

        span {
          color: #1890ff;
        }
      }
    }
  }
}

.part {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 15px 0;
}

.dialog-wrap {
  display: flex;
  height: 160px;
  flex-direction: column;

  .dialog-select {
    margin-bottom: 24px;

    label {
      width: 80px;
      text-align: left;
      display: inline-block;
    }

    label:before {
      content: '*';
      color: #f5222d;
      margin-right: 2px;
    }

    i {
      left: 140px;
      display: block;
      color: #f5222d;
      margin-top: 4px;
      position: absolute;
    }
  }

  .dialog-button {
    margin-top: 20px;
    margin-left: 110px;
  }

  .backImg {
    bottom: 0;
    width: 100%;
    opacity: 0.5;
    height: 44px;
    position: absolute;
  }
}
</style>
